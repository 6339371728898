import React from "react";

import "antd/dist/reset.css";
import { Provider } from "react-redux";
import ReactDOM from "react-dom/client";
import "react-toastify/dist/ReactToastify.css";
import { isMobile } from "react-device-detect";
import { ToastContainer } from "react-toastify";
import { SDKProvider } from "@telegram-apps/sdk-react";
import { TonConnectUIProvider } from "@tonconnect/ui-react";
import { ThirdwebProvider, trustWallet } from "@thirdweb-dev/react";
import { WebAppProvider, useExpand } from "@vkruglikov/react-telegram-web-app";

import "./index.css";
import { store } from "./store";
import { getAppKeyCoin } from "./utils";
import { Routers } from "./components/routers";
import { LaptopScreen } from "./components/lapton-screen";
import { TimestampError } from "./components/timestamp-error";
import { UserProvider } from "./components/contexts/user-context";
import { AnimationProvider } from "./components/contexts/animation-context";
import { LottieAnimationProvider } from "./components/contexts/lottie-animation-context";

const root = ReactDOM.createRoot(
  document.getElementById("root") as HTMLElement
);

const App = () => {
  const [isExpanded, expand] = useExpand();
  expand();

  const [isTimestampError, setIsTimestampError] =
    React.useState<boolean>(false);


    React.useEffect(() => {
      let startY: number = 0;
  
      const isScrollable = (element: HTMLElement) => {
        return element.scrollHeight > element.clientHeight;
      };
  
      const handleTouchStart = (event: TouchEvent): void => {
        startY = event.touches[0].clientY;
      };
  
      const handleTouchMove = (event: TouchEvent): void => {
        const currentY = event.touches[0].clientY;
        const target = event.target as HTMLElement;
  
        if (target && isScrollable(target)) {
          return;
        }
  
        if (window.scrollY === 0 && currentY > startY) {
          event.preventDefault();
        }
      };
  
      document.body.addEventListener("touchstart", handleTouchStart, { passive: true });
      document.body.addEventListener("touchmove", handleTouchMove, { passive: false });
  
      return () => {
        document.body.removeEventListener("touchstart", handleTouchStart);
        document.body.removeEventListener("touchmove", handleTouchMove);
      };
    }, []);
    
  const [now, setNow] = React.useState(Date.now());
  let previousTime = now;

  React.useEffect(() => {
    const interval = setInterval(() => {
      const currentTime = Date.now();

      if (
        currentTime > previousTime + 10 * 60 * 1000 ||
        currentTime < previousTime - 10 * 60 * 1000
      ) {
        setIsTimestampError?.(true);
      }

      setNow(currentTime);
      previousTime = currentTime;
    }, 1000);

    return () => clearInterval(interval);
  }, []);

  const isAppKeyCoin = getAppKeyCoin();

  if (isTimestampError) {
    return <TimestampError />;
  }

  if (!isMobile && !isAppKeyCoin) {
    return <LaptopScreen />;
  }

  return (
    <SDKProvider>
      <LottieAnimationProvider>
        <WebAppProvider>
          <TonConnectUIProvider
            manifestUrl={process.env.REACT_APP_WALLET_MANIFEST}
          >
            <ThirdwebProvider
              supportedWallets={[trustWallet({ recommended: true })]}
            >
              <Provider store={store}>
                <AnimationProvider>
                  <UserProvider>
                    <Routers />
                    <ToastContainer />
                  </UserProvider>
                </AnimationProvider>
              </Provider>
            </ThirdwebProvider>
          </TonConnectUIProvider>
        </WebAppProvider>
      </LottieAnimationProvider>
    </SDKProvider>
  );
};

root.render(<App />);
